import produce from 'immer';
import { uniqueId } from 'lodash';
import { append, contains, without } from 'ramda';
import { Action, IReducersImmer } from 'lib/types';
import { getReducerActions } from 'lib/utils';
import {
  BBCommonActionTypes,
  IOnModalToggleAction,
  IToggleOverlayPortalAction,
  IToggleSnackbarAction,
} from './action-types';
import { IBBCommonState, ISnackbarState } from './types';

const SnackbarState: ISnackbarState = {
  show: false,
  mode: '',
  text: '',
  internetOff: false,
  action: null,
  actionData: null,
  emoji: null,
  timeout: 3000,
  uuid: '',
  analyticsData: null,
};

const initialState: IBBCommonState = {
  snackbarState: SnackbarState,
  openedModalIds: [],
  openedOverlayPortal: false,
  scrollPosition: 0,
};

const reducers: IReducersImmer<IBBCommonState> = (draft) => ({
  [BBCommonActionTypes.TOGGLE_SNACKBAR]: (action: IToggleSnackbarAction) => {
    const { mode, text, linesLimit, extra } = action.payload;
    draft.snackbarState = {
      ...SnackbarState,
      uuid: uniqueId('snackbar-'),
      mode,
      text,
      linesLimit,
      action: extra?.action,
      actionData: extra?.actionData,
      analyticsData: extra?.analyticsData,
      emoji: extra?.emoji,
      show: true,
      timeout: extra?.timeoutMs ?? 3000,
    };
  },

  [BBCommonActionTypes.HIDE_SNACKBAR]: () => {
    draft.snackbarState = { ...draft.snackbarState, show: false };
  },

  [BBCommonActionTypes.TOGGLE_OVERLAY_PORTAL]: (action: IToggleOverlayPortalAction) => {
    const { opened, scrollPosition } = action.payload;

    draft.openedOverlayPortal = opened;
    draft.scrollPosition = scrollPosition;
  },

  [BBCommonActionTypes.ON_MODAL_TOGGLE]: (action: IOnModalToggleAction) => {
    const { id, show } = action.payload;
    if (show && !contains(id, draft.openedModalIds)) {
      draft.openedModalIds = append(id, draft.openedModalIds);
    }

    if (!show && contains(id, draft.openedModalIds)) {
      draft.openedModalIds = without([id], draft.openedModalIds);
    }
  },
});

const reducersActions = getReducerActions(reducers);

const reducer = (state: IBBCommonState = initialState, action: Action): IBBCommonState => {
  if (!reducersActions[action.type]) {
    return state;
  }

  try {
    return produce(state, (draft: IBBCommonState) => reducers(draft)[action.type](action));
  } catch (err) {
    return state;
  }
};

export default reducer;
